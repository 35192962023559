import { Box, Link, Stack } from '@mui/material';

import { DashedBorderLine } from './index';

const LINK_PROPS = {
  sx: { color: 'grey.500', fontWeight: 700, cursor: 'pointer' },
  underline: 'hover' as const,
  target: '_blank',
};

const AUTONE_BASE_URL = 'https://autone.io';

const AuthFooter = () => {
  return (
    <Box
      sx={{
        width: '100%',
        px: '52px',
      }}
    >
      <DashedBorderLine />
      <Stack
        direction="row"
        justifyContent={'center'}
        alignItems={'center'}
        sx={{ py: '30px' }}
      >
        <Stack gap={5} direction={'row'}>
          <Link
            href={`${AUTONE_BASE_URL}/terms-and-conditions/`}
            {...LINK_PROPS}
          >
            Terms
          </Link>
          <Link href={`${AUTONE_BASE_URL}/privacy-policy/`} {...LINK_PROPS}>
            Privacy
          </Link>
        </Stack>
      </Stack>
    </Box>
  );
};

export default AuthFooter;
