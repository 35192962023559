const ROOTS_AUTH = '';

export const AUTH_PATH_APP: {
  root: string;
  login: string;
  forgot: string;
  reset: string;
  signUp: string;
} = {
  root: ROOTS_AUTH,
  login: '/login',
  forgot: '/forgot-password',
  reset: '/reset-password',
  signUp: '/sign-up',
};
