import { amplifyCookieStorageConfig } from '@autone/ui';
import * as Sentry from '@sentry/react';
import { Amplify } from 'aws-amplify';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { CookieStorage } from 'aws-amplify/utils';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import '@autone/tailwind-config/global.css';

import App from './App';
// central AWS config file
import config from './config/aws-exports';
//@ts-ignore
import store from './redux/store';
//@ts-ignore
import reportWebVitals from './reportWebVitals';
//@ts-ignore
import * as serviceWorker from './serviceWorker';

// configure amplify
Amplify.configure({
  ...config,
});

cognitoUserPoolsTokenProvider.setKeyValueStorage(
  new CookieStorage(amplifyCookieStorageConfig),
);

// ----------------------------------------------------------------------

// configure sentry
Sentry.init({
  dsn: 'https://2c48cd945bc54279aa66834b61736b15@o1115656.ingest.sentry.io/4504236926173184',
  integrations: [Sentry.browserTracingIntegration()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment:
    process.env.NODE_ENV === 'development'
      ? 'localhost'
      : process.env.REACT_APP_BUILD_STAGE,
});

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

reportWebVitals();
